import { useCallback, useRef, useState } from 'react';
import { ReactComponent as PenIcon } from 'images/icons/pen.svg';
import { ReactComponent as TrashIcon } from 'images/icons/trash.svg';
import { InlineFieldContainer } from 'common/components/controls/InlineField/InlineFieldContainer';
import useClickAway from 'common/utils/hooks/useClickAway';
import { useApplicationData } from 'remote-state/applicationHooks';
import { useGetAIConfigData } from 'remote-state/aiConfigDataHook';
import { QUERIES_KEYS } from 'constant';
import {
  StyledCreatedTime,
  StyledIconsWrapper,
  StyledLinkItem,
  StyledLinkItemConfigure,
  StyledLinkItemWrapper,
} from './StyledLinks';
import useTexts from './useTexts';

export default function LinkItemComponent({
  link: { id, linkName, linkUrl, createdTime },
  disabled,
  changeCurrentLink,
  onFocus,
  required,
  isTemplatePage,
  error,
  isActiveField,
}) {
  const { data: isAiEnabled } = useApplicationData(QUERIES_KEYS.IS_AI_ENABLED);
  const { data: assistiveAI } = useGetAIConfigData(QUERIES_KEYS.ASSISTIVE_AI);
  const { linkNamePlaceholder, URLPlaceholder } = useTexts();
  const linkRowRef = useRef(null);
  const [editable, setEditable] = useState(false);
  useClickAway(linkRowRef, () => {
    setEditable(false);
  });
  const handleSaveValue = useCallback((val) => changeCurrentLink(val, id), [changeCurrentLink, id]);

  return (
    <StyledLinkItem
      className="link-item"
      isTemplatePage={isTemplatePage}
      error={error}
      ref={linkRowRef}
      editable={editable}
      active={isActiveField}
    >
      <StyledLinkItemWrapper widthSize linkName={linkName} active={isActiveField}>
        <InlineFieldContainer
          fieldName="linkName"
          text={linkName}
          onFocus={onFocus}
          handleSaveValue={handleSaveValue}
          placeholder={linkNamePlaceholder}
          disabled={disabled || !editable}
          required={required}
          isAiEnabled={isAiEnabled}
          assistiveAI={assistiveAI}
        />
      </StyledLinkItemWrapper>
      <StyledLinkItemWrapper active={isActiveField}>
        <InlineFieldContainer
          fieldName="linkUrl"
          fullwidth
          text={linkUrl}
          onFocus={onFocus}
          handleSaveValue={handleSaveValue}
          placeholder={URLPlaceholder}
          disabled={disabled || !editable}
          required={required}
          isAiEnabled={isAiEnabled}
          assistiveAI={assistiveAI}
        />
      </StyledLinkItemWrapper>
      <StyledLinkItemConfigure isTemplatePage={isTemplatePage} editable={editable}>
        {!isTemplatePage && <StyledCreatedTime editable={editable}>{createdTime}</StyledCreatedTime>}
        {!editable && (
          <StyledIconsWrapper isTemplatePage={isTemplatePage} className="icons_wrapper">
            <span>
              <PenIcon onClick={() => setEditable(true)} />
            </span>
            <span>
              <TrashIcon onClick={() => changeCurrentLink(linkName, id, 'delete')} />
            </span>
          </StyledIconsWrapper>
        )}
      </StyledLinkItemConfigure>
    </StyledLinkItem>
  );
}
