import { memo, useMemo } from 'react';
import { Avatar as MuiAvatar } from '@mui/material';

import { AVATAR_CONSTANTS } from 'constants/index';

import { CustomAvatar } from '../customAvatar';
import { GroupAvatar } from '../groupAvatar';
import { StyledAvatar } from '../style';
import { UnassignedAvatar } from '../unassignedAvatar';
import { UserAvatar } from '../userAvatar';


 const BasicAvatar = ({
  type = AVATAR_CONSTANTS.TYPES.USER,
  size = AVATAR_CONSTANTS.SIZES.MEDIUM,
  userName,
  profileImage,
  icon,
  disableHover,
  customStyles,
  className,
  isUserDetails,
  ...restProps
}) => {

  const AvatarComponent = useMemo(() => ({
      [AVATAR_CONSTANTS.TYPES.USER]: UserAvatar,
      [AVATAR_CONSTANTS.TYPES.ADMIN]: UserAvatar,
      [AVATAR_CONSTANTS.TYPES.EMAIL]: UserAvatar,
      [AVATAR_CONSTANTS.TYPES.GROUP]: GroupAvatar,
      [AVATAR_CONSTANTS.TYPES.UNASSIGNED]: UnassignedAvatar,
      [AVATAR_CONSTANTS.TYPES.CUSTOM]: CustomAvatar,
  }[type] || MuiAvatar), [type]);

  return (
    <StyledAvatar
      className="avatar-wrapper"
      unassigned={type === AVATAR_CONSTANTS.TYPES.UNASSIGNED ? 'true' : undefined}
      groupAvatar={type === AVATAR_CONSTANTS.TYPES.GROUP ? 'true' : undefined}
      isUserDetails={isUserDetails}
      disableHover={disableHover}
      profileImage={profileImage}
      size={size}
      {...customStyles}
      {...restProps}
    >
      <AvatarComponent
        userName={userName}
        profileImage={profileImage}
        icon={icon}
        customStyles={customStyles}
        className={className}
      />
    </StyledAvatar>
  );
};

export default memo(BasicAvatar);