import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { addFeatureFlagEvaluation } from './common/utils/datadogUtils';
import { isEmpty } from './common/utils/utils';

const FeatureFlagMonitor = () => {
  const flags = useFlags(null);
  useEffect(() => {
    if(!isEmpty(flags)) addFeatureFlagEvaluation(flags)
  }, [flags]);

  return null; // This component does not render anything
};

export default FeatureFlagMonitor;