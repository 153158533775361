import  BasicAvatar  from 'common/components/avatars/basicAvatar';
import Checkbox from 'common/components/checkbox';
import { ReactComponent as IconChecked } from 'images/icons/icon_checked.svg';
import { ReactComponent as IconArrow } from 'images/icons/icon_back.svg';
import Tooltip from '../tooltip';
import { StyledAssigneeItem } from './style';

export const AssigneeItem = ({ text, profileImage, type, isSelected, showArrowIcon, showCheckbox }) => (
  <StyledAssigneeItem className="assignee-item-wrapper">
    {showCheckbox && <Checkbox className="assignee" checked={isSelected} data-testid="assignee-checkbox-input" />}
    <BasicAvatar type={type} userName={text} profileImage={profileImage} disableHover />
    <Tooltip text={text} className="text" isTruncatedText />
    {isSelected && !showCheckbox && type !== 'group' && <IconChecked className="icon" />}
    {showArrowIcon && <IconArrow className="icon" />}
  </StyledAssigneeItem>
);
