import User from 'common/components/user';
import  BasicAvatar  from '../basicAvatar';

export const DetailedAvatar = ({
  type,
  size,
  userName,
  previewName,
  userId,
  profileImage,
  teamName,
  popper,
  disableHover,
  avatarStyles,
  userStyles,
  className,
}) => (
  <User userName={userName} userId={userId} teamName={teamName} popper={popper} customSx={userStyles}>
    <BasicAvatar
      type={type}
      size={size}
      userName={previewName}
      profileImage={profileImage}
      disableHover={disableHover}
      customStyles={avatarStyles}
      className={className}
    />
  </User>
);
