import { useMemo } from 'react';
import { isNone } from 'common/utils/utils';
import { StyledMenuItem } from 'common/components/dropdownSelect/SelectItem/SelectItem.styles';
import { InfoTypography } from 'common/components/dropdownSelect/InfoTypography';

import { AssigneeItem } from '../../../assigneeItem';
import useTexts from '../../useTexts';
import { useScrollbar } from '../useScrollbar';
import { StyledList } from '../adminsList/StyledList';

export const GroupList = (props) => {
  const {
    groupList,
    handleChange,
    currentAssigned,
    filteredGroups,
    showCheckboxes,
    showUnassigned,
    isEmptyResults,
    isGridFilter,
    maxHeight,
  } = props;
  const { unassignedText, emptyResultsText } = useTexts();
  const firstGroup = groupList[0];
  const { Container, scrollProps } = useScrollbar(maxHeight);

  const { showArrowIcon, showCheckbox } = useMemo(
    () => ({
      showArrowIcon: !!currentAssigned || isGridFilter,
      showCheckbox: showCheckboxes && !isGridFilter,
    }),
    [showCheckboxes, currentAssigned, isGridFilter],
  );

  const handleSelectGroup = (group) => {
    handleChange({ group: { ...group } });
  };

  const checkIfSelected = (groupName) => {
    let isSelected;
    if (currentAssigned) {
      isSelected = currentAssigned?.group?.groupName === groupName;
    } else if (filteredGroups) {
      if (isNone(groupName)) {
        isSelected = !!filteredGroups.find((o) => o.isUnassigned === true);
      } else {
        isSelected = !!filteredGroups.find((o) => o.groupName === groupName);
      }
    }
    return isSelected;
  };

  const renderUnassignedOption = () => {
    if (showUnassigned) {
      return (
        <StyledMenuItem
          className="option"
          key="-1"
          value="-1"
          onClick={handleSelectGroup}
          selected={checkIfSelected(null)}
        >
          <AssigneeItem
            text={unassignedText}
            type="unassigned"
            isSelected={checkIfSelected(null)}
            ShowCheckbox={showCheckboxes}
          />
        </StyledMenuItem>
      );
    }
  };

  return (
    <StyledList>
      {isEmptyResults ? (
        <InfoTypography message={emptyResultsText} />
      ) : (
        <Container {...scrollProps}>
          <div className="options_wrapper">
            {firstGroup && (
              <StyledMenuItem
                className="option"
                key={`${firstGroup.groupName}_g`}
                value={firstGroup.groupName}
                data-testid={firstGroup.groupName}
                onClick={() => handleSelectGroup(firstGroup)}
                selected={checkIfSelected(firstGroup.groupName)}
              >
                <AssigneeItem
                  text={firstGroup.groupName}
                  type="group"
                  isSelected={
                    firstGroup.groupName === currentAssigned?.group?.groupName ||
                    !!filteredGroups?.find((o) => o.groupName === firstGroup.groupName)
                  }
                  showArrowIcon={showArrowIcon && !isGridFilter}
                  showCheckbox={showCheckbox || isGridFilter}
                />
              </StyledMenuItem>
            )}
            {renderUnassignedOption()}
            {groupList.map((option) => {
              if (option.groupName !== firstGroup.groupName) {
                return (
                  <StyledMenuItem
                    className="option"
                    key={`${option.groupName}_g`}
                    value={option.groupName}
                    data-testid={option.groupName}
                    onClick={() => handleSelectGroup(option)}
                    selected={checkIfSelected(option.groupName)}
                  >
                    <AssigneeItem
                      text={option.groupName}
                      //TODO: Image property should be set once data is available from the backend.
                      type="group"
                      isSelected={checkIfSelected(option.groupName)}
                      showArrowIcon={showArrowIcon && !isGridFilter}
                      showCheckbox={showCheckbox || isGridFilter}
                    />
                  </StyledMenuItem>
                );
              }
              return null;
            })}
          </div>
        </Container>
      )}
    </StyledList>
  );
};
